import React, { useEffect, useState } from "react";
import HeroSlider from "../components/HeroSlider";
import ItemForSale from "../components/ItemForSale";
import axios from "axios";
import NewsItem from "../components/NewsItem";
import SiteSpecial from "../components/SiteSpecial";
import Brands from "../components/Brands";

function Home() {
  const [itemForSale, setItemForSale] = useState();
  const [newsItem, setNewsItem] = useState();
  const [siteSpecial, setSiteSpecial] = useState();
  const fetchLatestForSale = () => {
    axios
      .get(
        `${process.env.REACT_APP_SQL_HOST}/wp-json/wp/v2/for_sale_item?acf_format=standard&sold=false&orderby=date`
      )
      .then((res) => {
        setItemForSale(res.data[0]);
      });
  };
  const fetchLatestNews = () => {
    axios
      .get(
        `${process.env.REACT_APP_SQL_HOST}/wp-json/wp/v2/corby_news_item?acf_format=standard&per_page=1&page=1`
      )
      .then((res) => {
        setNewsItem(res.data[0]);
      });
  };
  const fetchSiteSpecial = () => {
    axios
      .get(
        `${process.env.REACT_APP_SQL_HOST}/wp-json/wp/v2/site_special?acf_format=standard&isfeatured=false`
      )
      .then((res) => {
        setSiteSpecial(res.data[0]);
      });
  };

  useEffect(() => {
    fetchLatestForSale();
    fetchLatestNews();
    fetchSiteSpecial();
  }, []);

  return (
    <main>
      <div className="hero-container">
        <HeroSlider />
        {siteSpecial && (
          <SiteSpecial
            itemImage={siteSpecial.acf.feature_image}
            itemHeading={siteSpecial.acf.special_heading}
            itemText={siteSpecial.acf.hero_text}
            itemTitle={siteSpecial.title.rendered}
          ></SiteSpecial>
        )}
        {itemForSale && (
          <ItemForSale
            itemImage={itemForSale.acf.main_image}
            itemTitle={itemForSale.title.rendered}
            itemText={itemForSale.content.rendered}
            itemDate={itemForSale.date}
            itemPrice={Number(itemForSale.acf.price).toLocaleString("en")}
            isHero={true}
          ></ItemForSale>
        )}
      </div>
      <div className="news-container ">
        {newsItem && (
          <NewsItem
            itemHeroImage={newsItem.acf.hero_image}
            itemTitle={newsItem.title.rendered}
            itemText={newsItem.excerpt.rendered}
            isHero={true}
            itemId={newsItem.id}
            itemDate={new Date(newsItem.date).toLocaleDateString("en-us", {
              weekday: "long",
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
          ></NewsItem>
        )}
      </div>

      <Brands></Brands>
    </main>
  );
}

export default Home;

import React, { useEffect, useState } from "react";
import axios from "axios";
import BrandCard from "./BrandCard";
import classNames from "classnames";

export default function Brands() {
  const [brandFilterChanging, setBrandFilterChanging] = useState(false);
  const [brands, setBrands] = useState([]);
  const [filteredBrands, setFilteredBrands] = useState([]);
  // const [isLoading]

  const [brandFilter, setBrandFilter] = useState("electronics");

  const changeBrandFilter = (brand) => {
    setBrandFilterChanging(true);
    setTimeout(() => {
      setBrandFilter(brand);
    }, 450);
  };

  const fetchBrands = () => {
    axios
      .get(
        `${process.env.REACT_APP_SQL_HOST}/wp-json/wp/v2/brand?acf_format=standard&per_page=100`
      )
      .then((res) => {
        res.data.sort((a, b) => {
          const brandA = a.title.rendered.toUpperCase();
          const brandB = b.title.rendered.toUpperCase();
          if (brandA < brandB) return -1;
          if (brandA > brandB) return 1;
          return 0;
        });
        setBrands(res.data);
        window.localStorage.setItem("brandList", JSON.stringify(res.data));
      });
  };
  useEffect(() => {
    if (brands) {
      let filterList = brands.filter((brand) => {
        //return brand.acf.product_category === brandFilter;
        return brand.acf.product_category.includes(brandFilter);
      });
      setFilteredBrands(filterList);
    }
  }, [brands, brandFilter]);
  useEffect(() => {
    setBrandFilterChanging(false);
    // }, 1500);
  }, [filteredBrands]);
  useEffect(() => {
    window.localStorage.clear();

    fetchBrands();
  }, []);

  return (
    <div className="brands-container">
      <div className="heading-section">
        <div className="section-heading dark-blue-text">Brands</div>
      </div>
      <div className="brand-filters">
        <div
          role="button"
          tabIndex="0"
          className={classNames(
            "brand-filter section-subheading",
            brandFilter === "electronics" ? "brand-filter-active" : ""
          )}
          onClick={() =>
            brandFilter !== "electronics"
              ? changeBrandFilter("electronics")
              : null
          }
        >
          Electronics
        </div>
        <div
          role="button"
          tabIndex="0"
          className={classNames(
            "brand-filter section-subheading",
            brandFilter === "analog" ? "brand-filter-active" : ""
          )}
          onClick={() =>
            brandFilter !== "analog" ? changeBrandFilter("analog") : ""
          }
        >
          Analog
        </div>
        <div
          role="button"
          tabIndex="0"
          className={classNames(
            "brand-filter section-subheading",
            brandFilter === "loudspeakers" ? "brand-filter-active" : ""
          )}
          onClick={() =>
            brandFilter !== "loudspeakers"
              ? changeBrandFilter("loudspeakers")
              : ""
          }
        >
          Loudspeakers
        </div>
        <div
          role="button"
          tabIndex="0"
          className={classNames(
            "brand-filter section-subheading",
            brandFilter === "accessories" ? "brand-filter-active" : ""
          )}
          onClick={() =>
            brandFilter !== "accessories"
              ? changeBrandFilter("accessories")
              : ""
          }
        >
          Accessories
        </div>
      </div>

      <ul
        className={classNames(
          "brand-list",
          brandFilterChanging ? "brand-list-transitioning" : ""
        )}
      >
        {filteredBrands &&
          filteredBrands.map((brand, index) => {
            return <BrandCard brand={brand} key={"brand" + index}></BrandCard>;
          })}
      </ul>
    </div>
  );
}
